import React from "react"
import { ImageVideo } from "../../ImageVideo/ImageVideo"
import { withImageText } from "./withImageText"
import { Outer, ImageWrapper, Inner } from "./ImageTextStyles"
import { TextButtonsGrid } from "../../TextButtonsGrid/TextButtonsGrid"

export const ImageText = withImageText(
  ({
    image,
    content,
    imagePosition,
    imagePercentage,
    backgroundColor,
    ratio,
    hideImageOnMobile,
    handlePromoClick,
  }) => (
    <Outer
      imagePosition={imagePosition}
      imagePercentage={imagePosition}
      onClick={handlePromoClick}
    >
      <ImageWrapper
        imagePercentage={imagePercentage}
        hideImageOnMobile={hideImageOnMobile}
      >
        <ImageVideo imageVideo={image} ratio={ratio} fullHeight />
      </ImageWrapper>
      <Inner
        backgroundColor={backgroundColor}
        imagePercentage={imagePercentage}
      >
        <TextButtonsGrid content={content} />
      </Inner>
    </Outer>
  ),
)

export default ImageText
