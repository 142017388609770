import tw, { styled, css } from "twin.macro"

export const Outer = styled.div`
  ${tw`flex`}
  ${({ imagePosition }) => {
    return imagePosition === "imageFirst"
      ? tw`flex-col md:flex-row`
      : tw`flex-col md:flex-row-reverse`
  }}
`

export const ImageWrapper = styled.div`
  ${({ imagePercentage }) => {
    return imagePercentage
      ? css`
          @media only screen and (min-width: 768px) {
            width: ${imagePercentage}%;
          }
        `
      : tw`w-full md:w-1/2`
  }}
  ${({ hideImageOnMobile }) => {
    return hideImageOnMobile ? tw`hidden md:block` : null
  }}
`

export const Inner = styled.div`
  ${tw`flex justify-center items-center`}
  ${({ backgroundColor }) => {
    return backgroundColor
      ? css`
          background-color: ${backgroundColor.hex};
        `
      : null
  }}

  ${({ imagePercentage }) => {
    return imagePercentage
      ? css`
          @media only screen and (min-width: 768px) {
            width: ${100 - imagePercentage}%;
          }
        `
      : tw`w-full md:w-1/2`
  }}
`
